<template>
  <div class="page">
    <div class="card">
      <div class="img">
        <van-image
          width="45vw"
          fit="contain"
          :src="detailInfo.image"
        />
      </div>
      <div class="detail">
        <van-cell-group inset>
          <div class="plate">
            <div v-if="showLoding" class="hold"></div>
          <span class="item">{{detailInfo.plate}}</span>
          <div v-if="showLoding" class="hold"></div>
          </div>
          <van-cell title="入场时间:" :value="detailInfo.enterTime" />
          <van-cell title="停车时长:" :value="detailInfo.duration" />
          <van-cell title="待缴车费:" :value="detailInfo.unpaidAmount" />
          <van-cell title="本次优惠:" :value="detailInfo.discountAmount" />
          <van-cell
            v-show="detailInfo.needAmount !== '0.00'"
            title="还需缴纳:"
            :value="detailInfo.needAmount"
            style="color: red;"
          />
          <van-cell
            v-show="detailInfo.needAmount == '0.00'"
            title="还需缴纳:"
            :value="detailInfo.needAmount"
          />
          <!-- <van-divider
            :style="{
              color: '#1989fa',
              borderColor: '#1989fa',
              padding: '0 1px',
            }"
          >
          </van-divider> -->
          <van-cell
            v-if="cardId"
            title="代金券号:"
            :value="`${cardId.slice(0, 4)}****${cardId.slice(-5, -1)}`"
          />
          <van-cell
            v-if="cardInfoo.todayRemainderNum"
            title="剩余张数:"
            :value="cardInfoo.todayRemainderNum"
          />
          <van-cell v-if="detailInfo.coupons.length>0" title="发放记录1:" :value="`${detailInfo.coupons[0].count} 张券`" />
          <van-cell v-if="detailInfo.coupons.length>1" title="发放记录2:" :value="`${detailInfo.coupons[1].count} 张券`" />
        </van-cell-group>
      </div>
      <div class="button">
        <div class="send" @click="send">
          <van-button type="info" size="large" :disabled="detailInfo.needAmount == '0.00'">{{detailInfo.needAmount == '0.00'?'免费通行':'一键抵扣'}}</van-button>
        </div>
        <div class="back" @click="back">
          <van-button type="info" size="large" color="#ababab">返回</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "carsearch",
  data() {
    return {
      value: "",
      cardId: "",
      token: "",
      cardInfo: {},
      showLoding:false,
    };
  },

  methods: {
    // 查询车票
    async getCard() {
      // this.$store.state.cardInfo = {}
      this.cardInfo = {};
      let res = await this.$api.getCard(this.token);
      if (res.data.code == "200") {
        this.cardInfo.couponId = res.data.data[0].couponId;
        this.cardInfo.todayRemainderNum = res.data.data[0].todayRemainderNum;
        this.cardInfo.discountValue = res.data.data[0].discountValue;
        this.$store.state.cardInfo = this.cardInfo;
      }else{
        this.showLoding = false
        return
      }
    },
    // 回到搜索
    back() {
      this.$emit("goSearch");
    },

    // 添加优惠券
    async send() {
      this.showLoding = true
      this.token = this.$store.state.token;
      let need = Number(this.$store.state.detailInfo.needAmount); 
        if (!need) {
          this.showLoding = false
        // Toast("免费出行");
        return;
      }
      need = Math.ceil(need / 3); //未付金额/3元 得到所需券数
      let res = await this.$api.Send(
        {
          effectiveDate: "",
          effectiveStartDate: "",
          img: "",
          lastEndTime: "",
          orderId: this.$store.state.orderId,
          parkId: this.$store.state.parkId,
          pkCouponRecordId: "",
          sendTimeCouponId: "",
          timeCouponId: "",
          couponIds: Array(need + 1).join(`${this.cardId},`),
        },
        this.token
      );
      // console.log(res)
      if (!res.data.code) {
        Toast(res.data.msg);
        this.showLoding = false
        return;
      }
      Toast('减免成功');
      this.secSearch();
      this.getCard();
      this.showLoding = false
    },
    // 二次查询
    secSearch() {
      try {
        // 二次查询获取车辆详细信息
        let res1 = this.$api.Detail(
          {
            orderId: this.$store.state.orderId,
            parkId: this.$store.state.parkId,
          },
          this.token
        );
        res1.then((res) => {
          if (res.data.code == "200") {
            // console.log("详细",res1.data.data)
            const newInfo = {};
            newInfo.duration = res.data.data.duration;
            newInfo.discountAmount = res.data.data.discountAmount;
            newInfo.enterTime = res.data.data.enterTime;
            newInfo.image = res.data.data.image;
            newInfo.lastDiscount = res.data.data.lastDiscount;
            newInfo.lastEndTime = res.data.data.lastEndTime;
            newInfo.needAmount = res.data.data.needAmount;
            newInfo.orderId = res.data.data.orderId;
            newInfo.parkName = res.data.data.parkName;
            newInfo.photoEnabled = res.data.data.photoEnabled;
            newInfo.pkCouponRecordId = res.data.data.pkCouponRecordId;
            newInfo.plate = res.data.data.plate;
            newInfo.timeCouponId = res.data.data.timeCouponId;
            newInfo.unpaidAmount = res.data.data.unpaidAmount;
            newInfo.versionFlag = res.data.data.versionFlag;
            newInfo.coupons =
              res.data.data.coupons.length > 0
                ? res.data.data.coupons
                : 0;
            this.$store.state.detailInfo = newInfo;
          }
        });
        // console.log("res2",res2)
      } catch (error) {
        Toast(error);
      }
    },
  },

  created() {
    if (this.$cookies.isKey("token")) {
      this.$store.state.token = this.$cookies.get("token");
      this.$store.state.username = this.$cookies.get("userName");
      this.$emit("toSearch");
    } else {
      this.$emit("toLogin");
    }
  },
  computed: {
    detailInfo() {
      return this.$store.state.detailInfo;
    },
    cardInfoo() {
      this.cardId = this.$store.state.cardInfo.couponId;
      return this.$store.state.cardInfo;
    },

    //  ctoken() {
    //   return !this.$store.state.token
    //     ? this.$cookies.get("token")
    //     : this.$store.state.token;
    // },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: url("../../assets/search.gif");
  width: 100vw;
  height: 90vh;
  position: fixed;
  background-size: 100% 100%;
  text-align: center;
  overflow: auto;
  .backbtn {
    position: fixed;
    top: 0;
    right: 0;
  }
  .card {
    width: 55vw;
    margin: 1vw auto;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text {
      color: #0078d7;
      margin-bottom: 50px;
    }
    .plate{
      width: 45vw;
      margin: 0 auto;
      height: 50px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 26px;
      font-weight: 700;
      color:#fff;
      .item{
        padding: 5px;
        background: #0078d7;
      }
      .hold{
        background: url("../../assets/hold.gif");
        width: 5vw;
  height: 7vh;
  background-size: 100% 100%;
        
      }
    }
    .detail {
      // position: absolute;
      // margin-top: 1vh;
      /deep/ .van-cell-group--inset {
        margin: 0 1vh;
      }
      .van-cell-group{
        box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.1);
      }
      /deep/ .van-cell {
        color:#fff;
        background: rgba(255, 255, 255, 0);
        position: relative;
        padding: 1vw 1vw;
        .van-cell__title {
          position: absolute;
          left: 2vw;
          font-weight: 700;
        }
        .van-cell__value {
          font-weight: 700;
          color:#fff;
        }
      }
    }
    .button {
      // padding-bottom: 5vh;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 2vw auto;
      width: 80%;
      font-weight: 700;
      .send {
        width: 80%;
      }
      .back {
        width: 30%;
      }
    }
  }
}
</style>