import Vue from 'vue';
import { Button } from 'vant';
import { Tab, Tabs } from 'vant';
import { Search } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Image as VanImage } from 'vant';
import { Popover } from 'vant';
import { Popup } from 'vant';
import { Sticky } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Empty } from 'vant';
import { Col, Row } from 'vant';
import { Skeleton } from 'vant';
import { Grid, GridItem } from 'vant';
import { Divider } from 'vant';
import { SwipeCell } from 'vant';
import { Card } from 'vant';
import { Picker } from 'vant';
import { NavBar } from 'vant';
import { Dialog } from 'vant';
import { Loading } from 'vant';
import { NoticeBar } from 'vant';
import { Icon } from 'vant';

Vue.use(Icon);
Vue.use(NoticeBar);
Vue.use(Loading);

Vue.use(Dialog);
Vue.use(NavBar);
Vue.use(Picker);
Vue.use(Card);
Vue.use(SwipeCell);
Vue.use(Divider);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Skeleton);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Sticky);
Vue.use(Popup);
Vue.use(Popover);
Vue.use(VanImage);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);